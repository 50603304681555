import React, { Component, useEffect, useRef, useState } from "react";
import "./header.css";
import logo from "../images/smellsandsoundslogo-no-background.png";
import { FaSearch, FaBars, FaStar } from "react-icons/fa";

function Header(props) {
  const { handleSearchClick } = props;
  const [menuStatus, setStatus] = useState("closed");
  const [tourlistHeight, setTourListHeight] = useState();
  const [headerHeight, setHeaderHeight] = useState();
  const [headerClass, setHeaderClass] = useState("overBanner");

  const handleLogoClick = () => {
    window.location = "/";
  };
  useEffect(() => {
    setHeaderClass(
      window.location.pathname == "/"
        ? "overBanner"
        : window.location.pathname.startsWith("/tour")
        ? "noBanner"
        : window.location.pathname.startsWith("/contactus")
        ? "noBanner"
        : "colorheader"
    );
    //setHeaderClass(window.location.pathname == "/" ? "overBanner" : "overBanner");
    setTourListHeight(document.querySelector("#tourlist").offsetHeight);
    setHeaderHeight(document.querySelector("#headerWrap").offsetHeight);

    let homepage = window.location.pathname == "/" ? true : false;

    if (homepage) {
      window.addEventListener("scroll", () => {
        let banner = document.getElementsByClassName("banner")[0];
        let bannerTop = banner.getBoundingClientRect().y;
        if (bannerTop >= 0) {
          document.getElementById("backcolor").style.opacity = "0";
        }
        if (bannerTop < -20) {
          document.getElementById("backcolor").style.opacity = "1";
        }
        if (bannerTop < -400) {
          document.getElementById("headerWrap").style.opacity = "0";
          document.getElementById("headerWrap").style.visibility = "hidden";
        }
        if (bannerTop > -50) {
          document.getElementById("headerWrap").style.opacity = "1";
          document.getElementById("headerWrap").style.visibility = "visible";
        }
      });
    }
  }, []);
  /*
  useEffect(() => {
    console.log(menuStatus);
  }, [menuStatus]);
  */

  const menu = () => {
    if (menuStatus == "opened") {
      if (headerClass == "overBanner") {
        document.getElementById("headerWrap").classList.add("overBanner");
        document.getElementById("headerWrap").style.backgroundColor =
          "transparent";
      }

      if (headerClass == "noBanner") {
        document.getElementById("headerWrap").classList.add("noBanner");
        document.getElementById("headerWrap").style.backgroundColor =
          "transparent";
      }
      document.getElementById("headerWrap").style.height = headerHeight + "px";
      document.getElementById("bottom").style.overflow = "hidden";
      setStatus("closed");
    } else {
      if (headerClass == "overBanner") {
        document.getElementById("headerWrap").style.backgroundColor = "#383e45";
        document.getElementById("headerWrap").classList.remove("overBanner");
      }

      if (headerClass == "noBanner") {
        document.getElementById("headerWrap").style.backgroundColor = "#383e45";
        document.getElementById("headerWrap").classList.remove("noBanner");
      }

      setTourListHeight(document.querySelector("#tourlist").offsetHeight);
      let tourlistheight = document.querySelector("#tourlist").offsetHeight;
      document.getElementById("headerWrap").style.height =
        tourlistheight + headerHeight + 20 + "px";

      document.getElementById("bottom").style.overflow = "visible";
      window.scrollTo(0, 0);
      setStatus("opened");
    }
  };

  const showSearch = () => {}; //end showSearch

  return (
    <>
      <header className={"header-wrap " + headerClass} id="headerWrap">
        <div className="top">
          <button
            onClick={() => {
              //console.log(window.location);
              if (window.location.pathname !== "/contactus")
                window.location = "/contactus";
            }}
          >
            Contact Us
          </button>
        </div>
        <div className="bottom" id="bottom">
          <div className="bottom-backcolor" id="backcolor"></div>
          <img onClick={handleLogoClick} src={logo}></img>
          <ul id="tourlist">
            <li
              onClick={() => {
                window.location = "/tours?search=sight";
              }}
            >
              TOURS TO SEE
            </li>
            <li
              onClick={() => {
                window.location = "/tours?search=smell";
              }}
            >
              TOURS THAT SMELL
            </li>
            <li
              onClick={() => {
                window.location = "/tours?search=hear";
              }}
            >
              TOURS TO HEAR
            </li>
            <li
              onClick={() => {
                window.location = "/tours?search=touch";
              }}
            >
              TOURS TO TOUCH
            </li>
            <li
              onClick={() => {
                window.location = "/tours?search=taste";
              }}
            >
              TOURS TO TASTE
            </li>
          </ul>

          <FaBars className="bars" onClick={menu} />
          <FaSearch
            className="search"
            onClick={() => {
              handleSearchClick("flex");
            }}
          />
        </div>
      </header>
    </>
  );
}

export default Header;
