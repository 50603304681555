import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import { useCSVReader, usePapaParse } from "react-papaparse";
//const { readRemoteFile } = usePapaParse();
import Index from "./components/index";
import Footer from "./components/footer";
import Header from "./components/header";
import TourPage from "./components/tour-page";
import Search from "./components/search";
import Searchcontainer from "./components/searchcontainer";
import Alltours from "./components/alltours";
import Cloudinary from "./components/cloudinary";
import Contact from "./components/contact";
import Terms from "./components/terms";

import { AdvancedImage } from "@cloudinary/react";
//import { Cloudinary } from "@cloudinary/url-gen";
import { FolderSearch } from "./components/cloudinary/search";
import Ratings from "./components/rating";
import MyCaledar from "./components/calendar";

import { CookieBanner } from "@keepist/react-gdpr-cookie-banner";

function App() {
  const [time, setTime] = useState("");
  const [searchView, setSearchView] = useState("none");
  const { readRemoteFile } = usePapaParse();
  const [tourdata, setTourData] = useState({
    title: "",
    description: "",
    price: "",
    images: [],
    specs: {},
  });
  useEffect(() => {
    const d = new Date();
    let time = d.getTime();
    setTime(time);
    /*
    (async function() {
      const image = await FolderSearch("Jungle Book Afternoon Tea");  
      //const image = await FolderSearch("primary");  
      console.log(image);    
      //setmyImage(cld.image(image));
    })();
    */
  }, []);

  const handleTourPage = (id, callback) => {
    const d = new Date();
    let time = d.getTime();

    let tourObject = { ...tourdata };
    /*
    let url =
      "https://docs.google.com/spreadsheets/u/0/d/e/2PACX-1vSBUkuqysc-3Lv7ip0J7Dt0Oi1mpzovcDJ9-3If8nvXsDXGDO0HfEjqKo0k6trVdAAQJqP3NPJCo4hW/pub?gid=0&single=true&output=csv";
      readRemoteFile(url, {
        header: true,
      complete: (results) => {
        const data = results.data;
        data.forEach(d=>{
          if(d.id === id){
            let row = {...d};
            row.images = JSON.parse(d.images);
            row.specs = JSON.parse(d.specs);
            //alert(JSON.stringify(data));
            //console.log(JSON.stringify(d));
            setTourData(row);
            callback(row);
          }
        })
        
      },
    });
    */

    fetch(
      //"https://cleversnail.backendless.app/api/data/tourdata?where=id%3D" + id
      "https://jrnsmap.com/ssol/tourdata?where=id%3D" + id
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.length > 0) {
          setTourData(data[0]);
          callback(data[0]);
        } else {
          window.location = "/";
        }
      });

    //tourObject.title = "Test";
    //setTourData(tourObject);
  };

  const handleReviewSearch = (id, callback) => {
    //"https://cleversnail.backendless.app/api/data/reviews?pageSize=100&where=id%3D17"
    fetch(
      //"https://cleversnail.backendless.app/api/data/reviews?pageSize=100&where=id%3D" + id
      "https://jrnsmap.com/ssol/reviews?where=id%3D" + id
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.length > 0) {
          callback(data);
        }
      });
  }; //end handleReviewSearch

  const handleAllTours = (callback) => {
    fetch(
      //"https://cleversnail.backendless.app/api/data/tourdata?pageSize=100&sortBy=%60created%60%20desc"
      "https://jrnsmap.com/ssol/tourdata"
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        callback(data);
      });
  }; //end handleAllTours

  const handleDatabaseCall = (sql, callback) => {
    //let url = "https://cleversnail.backendless.app/api/data/tourdata" + sql;
    let url = "https://jrnsmap.com/ssol/tourdata" + sql;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data[0].category.split(","));
        //console.log(JSON.parse(data[0]["images"])[0]);
        callback(data);
      });
  };

  const handleSearchClick = (x) => {
    setSearchView(x);
  };

  return (
    <>
      <Header handleSearchClick={handleSearchClick} />
      <Searchcontainer
        view={searchView}
        handleDatabaseCall={handleDatabaseCall}
        handleSearchClick={handleSearchClick}
      />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Index
                handleDatabaseCall={handleDatabaseCall}
                handleAllTours={handleAllTours}
              />
            }
          />
          <Route
            path="/tours"
            element={
              <Alltours
                handleAllTours={handleAllTours}
                handleDatabaseCall={handleDatabaseCall}
              />
            }
          />
          <Route
            path="/cloudinary"
            element={<Cloudinary handleDatabaseCall={handleDatabaseCall} />}
          />
          <Route
            path="/tour"
            element={
              <TourPage
                handleTourPage={handleTourPage}
                handleReviewSearch={handleReviewSearch}
                tourdata={tourdata}
              />
            }
          />
          <Route
            path="/search"
            element={<Search handleDatabaseCall={handleDatabaseCall} />}
          />

          <Route path="/contactus" element={<Contact />} />

          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
      <Footer />

    </>
  );
}

export default App;
