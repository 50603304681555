import React from "react";
import "./search.css";
import Search from "./search";
import { AiOutlineClose } from "react-icons/ai";

const Searchcontainer = (props) => {
  const { handleDatabaseCall, view, handleSearchClick } = props;
  return (
    <>
      <div className="search-full-page" style={{ display: view }}>
        <div
          className="search-close"
          onClick={() => {
            handleSearchClick("none");
          }}
        >
          <span>CLOSE</span>
          <AiOutlineClose />
        </div>
        <h2>Search our collection</h2>
        <Search handleDatabaseCall={handleDatabaseCall} />
      </div>
    </>
  );
};

export default Searchcontainer;
