export function FolderSearch(folder) {
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  var myInit = {
    method: "GET",
    headers: myHeaders,
  };

  return new Promise((resolve) => {
    const url =
      "https://res.cloudinary.com/dnmeepjg7/image/list/" +
      encodeURIComponent(folder) +
      ".json";
    let imageArray = [];

    try {
      fetch(url, myInit)
        .then((response) => response.text())
        .then((_data) => {
          const data = _data ? JSON.parse(_data) : {};

          //console.log(data.resources);

          if (data.resources) {
            data.resources.map((item, index) => {
              const public_id = encodeURIComponent(item.public_id);
              //const public_id = data.resources[0].public_id;
              const imageURL =
                "https://res.cloudinary.com/dnmeepjg7/image/upload/v1/" +
                public_id;
              imageArray.push(imageURL);
            }); //end map

            //get primary
            fetch(
              "https://res.cloudinary.com/dnmeepjg7/image/list/" +
                encodeURIComponent("primary") +
                ".json",
              myInit
            )
              .then((response) => response.text())
              .then((_data) => {
                const data = _data ? JSON.parse(_data) : {};
                //console.log(data.resources);
                let primaryArray = [];
                data.resources.map((item, index) => {
                  const public_id = encodeURIComponent(item.public_id);
                  //const public_id = data.resources[0].public_id;
                  const imageURL =
                    "https://res.cloudinary.com/dnmeepjg7/image/upload/v1/" +
                    public_id;
                  //imageArray.push(imageURL);
                  primaryArray = imageArray.filter((x) => {
                    return x !== imageURL;
                  });

                  if (decodeURIComponent(imageURL).includes(folder)) {
                    primaryArray.unshift(imageURL);
                    //console.log(primaryArray);
                    resolve(primaryArray);
                  }
                  if (index >= data.resources.length) {
                    resolve([primaryArray]);
                  }
                });
              });

            //-----------get primary
          } //end if
          else {
            resolve([]);
          }
        });
    } catch {
      console.log("Error");
      resolve(imageArray);
    }
  }); //end Promise
} //end search
