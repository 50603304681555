import React, { Component, useEffect, useState } from "react";
import "./index.css";
import Header from "./header";
import Feature from "./feature";
import { FaStar, FaCarAlt, FaCheckCircle, FaChild } from "react-icons/fa";
import Search from "./search";
import Carousel from "./carousel";
import Mycarousel from "./mycarousel";
import Snapcroll from "./snapcroll";

function Index(props) {
  const { handleAllTours, handleDatabaseCall } = props;
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    handleDatabaseCall("?where=category%20LIKE%20'%25featured%25'", (data) => {
      //console.log(data);
      let featuresarray = [];
      data.forEach(async (row) => {
        featuresarray.push(
          <Feature
            Title={row.title}
            Url={"/tour?id=" + row.id}
            Image={row.images[0]}
            Description={row.description}
            Amount={row.price}
          />
        );
      });
      setFeatures(featuresarray);
    }); //end databaseCall

    //handleDatabaseCall("", () => {});

    window.addEventListener("load",()=>{
      const details1 = document.getElementById("details-1");
      const details2 = document.getElementById("details-2");
      if(details1.offsetHeight > details2.offsetHeight){
        details2.style.height = details1.offsetHeight + "px";
      }else{
        details1.style.height = details2.offsetHeight + "px";
      }
    })


  }, []);
  return (
    <>
      <section className="banner">
        <span className="backimage"></span>
        <div className="dark-filter"></div>
        <div className="title-section">
          <span className="title">
            EXPERIENCE THE CITY THROUGH ALL YOUR SENSES
          </span>
          <span className="undertitle">ONE OF A KIND TOURS OF LONDON</span>
        </div>
        <Search handleDatabaseCall={handleDatabaseCall} />
      </section>

      <section className="feature-section">
        <span className="feature-section-title">TOURS OF THE WEEK</span>
        <div className="feature-wrap">{features}</div>
      </section>

      <section className="details">
        <div className="details-wrap">
          <div className="details-card">
            <div id="details-1" className="details-right">
              <div className="details-title right">
                <span>White Glove Service</span>
                <FaStar />
              </div>
              <div className="details-description right">
                <span>
                  Throughout the centuries wearing white gloves while being of
                  service exemplified nobility, integrity and discipline. We
                  follow in this tradition by providing superior customer
                  service to our clientele.
                </span>
              </div>
            </div>
            <div className="details-right">
              <div className="details-title right">
                <span>Black Cabbie Transportation</span>
                <FaCarAlt />
              </div>
              <div className="details-description right">
                <span>
                  Black Cabbies have a long tradition of navigating the streets
                  in London. Why not travel within the city, or outside of it,
                  in a uniquely British mode of transportation. They are roomy
                  and comfortable and the drivers, who must memorize all the
                  roads and landmarks within a six-mile radius of Charing Cross,
                  make superlative guides for the city they love.
                </span>
              </div>
            </div>
          </div>

          <div className="details-divider">
            <div className="divider"></div>
            <div className="divider line"></div>
          </div>

          <div className="details-card">
            <div id="details-2" className="details-top">
              <div className="details-title left">
                <span>Attention To Detail</span>
                <FaCheckCircle />
              </div>
              <div className="details-description left">
                <span>
                  Let our teams expert knowledge arrange a tour(s) for you to
                  make your vacation/travel as seamless and fun as possible!
                </span>
              </div>
            </div>
            <div className="details-top">
              <div className="details-title left">
                <span>British Nanny</span>
                <FaChild />
              </div>
              <div className="details-description left">
                <span>
                  There are nanny's and there are nanny's. British nanny's are
                  the latter. A child minder as they are called, can be made
                  available for any situation you may need assistance with.
                  Someone to stay at the hotel with your child(ren) while you
                  take a night on the town? Or perhaps someone to travel with
                  you to take some of the work of your shoulders? Our British
                  nanny's are registered, hold a pediatric first aid
                  qualification and are fully insured. Please inquire!
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="snippet"></section>

      <section className="index-alltours-section">
        <span style={{textAlign: "center"}} className="feature-section-title">All Available Tours</span>
        <Snapcroll handleAllTours={handleAllTours} />
      </section>
    </>
  );
}

export default Index;
