import React, { useEffect, useState } from "react";
import "./card.css";
import { FaStar, FaEye } from "react-icons/fa";
import { GiNoseFront, GiTongue } from "react-icons/gi";
import { TbEar } from "react-icons/tb";
import { TbHandClick } from "react-icons/tb";

const Card = (props) => {
  const [icons, setIcons] = useState([]);
  const [image, setImage] = useState(props.Image);
  const handleCardClick = (url) => {
    window.location = url;
  };

  const { Data } = props;
  useEffect(() => {
    const categories = Data.category.split(",");
    let iconArray = [];
    //console.log(Data.title, categories);
    categories.forEach((category) => {
      switch (category) {
        case "featured":
          iconArray.push(<FaStar className="gold-star" />);
          break;
        case "sight":
          iconArray.push(<FaEye />);
          break;
        case "smell":
          iconArray.push(<GiNoseFront />);
          break;
        case "hear":
          iconArray.push(<TbEar />);
          break;
        case "touch":
          iconArray.push(<TbHandClick />);
          break;
        case "taste":
          iconArray.push(<GiTongue />);
          break;
      } //end switch
    });
    setIcons(iconArray);
  }, []);

  useEffect(() => {
    console.log(image);
  }, [image]);

  return (
    <>
      <div
        className="card-container"
        onClick={() => {
          handleCardClick(props.Url);
        }}
      >
        <div
          className="card-image" data-image={"url(" + image + ")"}
          style={{ backgroundImage: "url(" + image + ")" }}
        >
          <div className="card-info">
            <h3>{props.Title}</h3>
            <div className="card-footer">
              <span>{props.Amount}</span>
              <div className="senses">{icons}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
