import React, { useEffect, useState } from "react";
import "./index.css";
import { FolderSearch } from "./search";
const Cloudinary = (props) => {
  const { handleDatabaseCall } = props;
  const [list, setList] = useState();
  const [json, setJson] = useState();
  useEffect(() => {
    const sql = "";
    handleDatabaseCall(sql, (data) => {
      let cardArray = [];
      data.map((row, index) => {
        cardArray.push(
          <li
            onClick={() => {
              getJson(row.title);
            }}
          >
            {row.title}
          </li>
        );
      });
      setList(cardArray);
    }); //end handleAllTours
  }, []);

  const getJson = async (title) => {
    console.log("clicked: ", title);
    const j = await FolderSearch(title);
    setJson(JSON.stringify(j));
  };

  return (
    <>
      <div className="cloudinary-container">
        <ul>{list}</ul>
        <textarea value={json}></textarea>
      </div>
    </>
  );
};

export default Cloudinary;
