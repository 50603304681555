import React, { useEffect, useState } from "react";
import "./timeline.css";
import "animate.css";

const Timeline = (props) => {
  const { itinarary } = props;
  const [list, setList] = useState();
  useEffect(() => {
    window.addEventListener("load", sizeTimeline);
    window.addEventListener("resize", sizeTimeline);
  }, []);

  useEffect(() => {
    if (itinarary) {
      let listArray = [];
      itinarary.map((x, index) => {
        const type =
          x.color == "green"
            ? " timeline__event--type3"
            : x.color == "purple"
            ? " timeline__event--type1"
            : x.color == "blue"
            ? " timeline__event--type2"
            : " timeline__event--type3";
        listArray.push(
          <div className={"timeline__event" + type}>
            <div className="timeline__event__icon ">
              <div className="timeline__event__date">{x.time}</div>
            </div>
            <div className="timeline__event__content ">
              <div className="timeline__event__title">{x.title}</div>
              <div className="timeline__event__description">
                <p>{x.description}</p>
              </div>
            </div>
          </div>
        );
      });
      setList(listArray);
    } //end if itinarary
  }, [itinarary]);

  useEffect(() => {
    sizeTimeline();
  }, [list]);

  const sizeTimeline = () => {
    var timelines = document.getElementsByClassName("timeline__event");
    if (timelines)
      if (timelines.length > 1)
        for (let index = 0; index < timelines.length - 1; index++) {
          //console.log(timelines[0].clientHeight);
          const element = timelines[index];
          const nextElement = timelines[index + 1];
          const myHeight =
            element.clientHeight / 2 + nextElement.clientHeight / 2 + 40;
          const myTop = element.clientHeight / 2;
          element.setAttribute(
            "style",
            "--dataHeight: " + myHeight + "px;--dataTop: " + myTop + "px;"
          );
        }
  }; //end sizeTimeline

  return (
    <>
      <div
        className="timeline"
        style={{ display: itinarary ? "flex" : "none" }}
      >
        {list}
      </div>
    </>
  );
};

export default Timeline;
