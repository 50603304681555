import "./footer.css";
import React from "react";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
function Footer(props) {
  return (
    <>
      <footer className="page-footer">
        <div className="footer-top">
          <span>Contact Us</span>
          <ul>
            <li>
              <FaPhoneAlt className="icon-space" /> +44 (0) 7824 163769
              (London/UK)
            </li>
            <li>
              <FaPhoneAlt className="icon-space" /> +1 (502)-712-2979 (USA)
            </li>
            <li>
              <FaPhoneAlt className="icon-space" /> +1 (850)-607-0364 (USA)
            </li>

            <li>
              <FaEnvelope className="icon-space" />{" "}
              support@smellsandsoundsoflondon.com
            </li>
          </ul>
        </div>
        <div className="footer-bottom">
          Copyright © Smells and Sounds of London
        </div>
      </footer>
    </>
  );
}

export default Footer;
