import React, { useEffect, useState } from "react";
import "./search.css";
import { HiOutlineMap } from "react-icons/hi";
const Search = (props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const { handleDatabaseCall } = props;
  const [results, setResults] = useState();

  const navigate = (id) => {
    document.getElementById("results").style.display = "none";
    document.getElementById("search").value = "";
    window.location = "/tour?id=" + id;
  };

  useEffect(() => {
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        console.log(search);
        // Send Axios request here
        setLoading(true);
        handleInputSearch(search);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [search]);
  const handleInputSearch = (e) => {
    //const value = e.target.value;
    const value = e;
    const sql =
      "?where=(category%20LIKE%20'%25" +
      value +
      "%25'%20OR%20title%20LIKE%20'%25" +
      value +
      "%25')";
    if (value != "") {
      handleDatabaseCall(sql, (data) => {
        if (data.length > 0) {
          document.getElementById("results").style.display = "block";
          let resultArray = [];
          //console.log(data);
          data.forEach((row) => {
            //console.log("Title", row.title);
            resultArray.push(
              <li
                onClick={() => {
                  navigate(row.id);
                }}
              >
                <HiOutlineMap className="list-icon" />
                {row.title}
              </li>
            );
          }); //end foreach
          setResults(resultArray);
          setLoading(false);
        } else {
          document.getElementById("results").style.display = "none";
          setResults(null);
          setLoading(false);
        }
      });
    } //end if not empty
    else {
      document.getElementById("results").style.display = "none";
      setResults(null);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="search-container">
        <div className="search-inner-container">
          <div
            style={{ display: loading ? "inline-block" : "none" }}
            className="lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <input
            placeholder="Search..."
            id="search"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          <ul
            style={{ paddingBottom: results ? "10px" : 0 }}
            id="results"
            className="search-results"
          >
            {results}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Search;
