import "./snapcroll.css";
import React, { useEffect, useState } from "react";
import Feature from "./feature";
import Card from "./card";

function Snapcroll(props) {
  const { handleAllTours } = props;
  const [cards, setCards] = useState([]);
  useEffect(() => {
    console.log("Call Tours");
    handleAllTours((data) => {
      let cardArray = [];
      data.forEach((row) => {
        cardArray.push(
          <div className="snapscroll-item">
            <Card
              Url={"/tour?id=" + row.id}
              Image={row.images[0]}
              Title={row.title}
              Description={row.description}
              Amount={row.price}
              Data={row}
            />
          </div>
        );
      });

      setCards(cardArray);

      const slider = document.querySelector(".snapscroll-container");
      let mouseDown = false;
      let startX, scrollLeft;

      let startDragging = function(e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };
      let stopDragging = function(event) {
        mouseDown = false;
      };

      slider.addEventListener("mousemove", (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      });

      // Add the event listeners
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);
    });
  }, []);
  return (
    <>
      <div className="snapscroll-container">{cards}</div>
    </>
  );
}

export default Snapcroll;
