import React from "react";
import "./contact.css";
import { FaPhoneAlt } from "react-icons/fa";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { FaTwitterSquare, FaTiktok } from "react-icons/fa";
import { AiOutlineInstagram, AiFillFacebook } from "react-icons/ai";
const Contact = (props) => {
  return (
    <>
      <section className="tour-specs">
        <figure className="bannercover"></figure>
        <div className="contacts-title-container">
          <h1 style={{ color: "white", fontSize: "40px" }}>Get in touch</h1>
        </div>
      </section>
      <section className="contact-container">
        <div className="contacts-divider">
          <div>
            <div className="contacts-phone contact-card">
              <FaPhoneAlt />
              <span>Talk to a rep</span>
              <p>
                <span>Need help with a tour? We can help.</span>
                <span>Call one of our agents to chat.</span>
              </p>
              <ul>
                <li>+1 (502)-712-2979 (USA)</li>
                <li>+1 (850)-607-0364 (USA)</li>
                <li>+44 (0) 7824 163769 (London/UK)</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="contacts-email contact-card">
              <TfiHeadphoneAlt />
              <span>Contact customer support</span>
              <p>
                <span>Don't feel like picking up the phone?</span>
                <span>Send us an email.</span>
              </p>
              <button
                onClick={() => {
                  window.location.href =
                    "mailto:support@smellsandsoundsoflondon.com";
                }}
              >
                Contact us
              </button>
            </div>
          </div>
        </div>
        <h2>Social Links</h2>
        <div className="social-links">
          <div className="social-card">
            <FaTwitterSquare />
            <h3>Twitter</h3>
            <span>@ss_ofLondon</span>
          </div>

          <div className="social-card">
            <AiOutlineInstagram />
            <h3>Instagram</h3>
            <span>@smellsandsoundsoflondon</span>
          </div>

          <div className="social-card">
            <FaTiktok />
            <h3>Tiktok</h3>
            <span>@smellsandsoundsldn</span>
          </div>

          <div className="social-card">
            <AiFillFacebook />
            <h3>Facebook</h3>
            <span>@smellsandsounds</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
