import React, { useEffect, useState } from "react";
import "./alltours.css";
import Card from "./card";
import Search from "./search";

import { FaStar, FaEye } from "react-icons/fa";
import { GiNoseFront, GiTongue } from "react-icons/gi";
import { TbEar } from "react-icons/tb";
import { TbHandClick } from "react-icons/tb";

const Alltours = (props) => {
  const { handleAllTours, handleDatabaseCall } = props;
  const [cards, setCards] = useState([]);
  useEffect(() => {
    window.addEventListener("load", () => {
      document
        .getElementsByClassName("bottom")[0]
        .scrollIntoView({ behavior: "smooth" });
    });

    const d = new Date();
    let time = d.getTime();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let search = urlParams.get("search");

    try {
      const icon = document.querySelector("div[data-search='" + search + "']");
      icon.style.color = "gold";
    } catch {}

    if (search === null) search = "";
    const sql =
      "?pageSize=100&where=(category%20LIKE%20'%25" +
      search +
      "%25'%20OR%20title%20LIKE%20'%25" +
      search +
      "%25')";

    handleDatabaseCall(sql, (data) => {
      let cardArray = [];
      data.map((row, index) => {
        cardArray.push(
          <div className="snapscroll-item">
            <Card
              Url={"/tour?id=" + row.id}
              Image={row.images[0]}
              Title={row.title}
              Description={row.description}
              Amount={row.price}
              Data={row}
              key={"pagesearch " + index + time + Math.random()}
            />
          </div>
        );
      });
      setCards(cardArray);
    }); //end handleAllTours
  }, []);

  const handleInputSearch = (e) => {
    const filterIcons = document.getElementsByClassName("filter-icons")[0];
    for (let index = 0; index < filterIcons.children.length; index++) {
      const element = filterIcons.children[index];
      element.style.color = "white";
    }

    const d = new Date();
    let time = d.getTime();
    const search = e.target.value;
    const sql =
      "?where=(category%20LIKE%20'%25" +
      search +
      "%25'%20OR%20title%20LIKE%20'%25" +
      search +
      "%25')";

    handleDatabaseCall(sql, (data) => {
      let cardArray = [];
      data.map((row, index) => {
        cardArray.push(
          <div className="snapscroll-item">
            <Card
              Url={"/tour?id=" + row.id}
              Image={row.images[0]}
              Title={row.title}
              Description={row.description}
              Amount={row.price}
              Data={row}
              key={"inputsearch " + index + time}
            />
          </div>
        );
      });
      setCards(cardArray);
    }); //end handleAllTours
  }; //

  const handleiconClick = (e) => {
    const d = new Date();
    let time = d.getTime();
    const element = e.currentTarget;
    for (
      let index = 0;
      index < element.parentElement.children.length;
      index++
    ) {
      const el = element.parentElement.children[index];
      el.style.color = "white";
    }

    element.style.color = "gold";
    const search = element.getAttribute("data-search");
    //window.location = "/tours?search=" + search;

    const sql =
      "?where=(category%20LIKE%20'%25" +
      search +
      "%25'%20OR%20title%20LIKE%20'%25" +
      search +
      "%25')";

    handleDatabaseCall(sql, (data) => {
      let cardArray = [];
      data.map((row, index) => {
        //console.log(row.category.split(","));
        cardArray.push(
          <div className="snapscroll-item">
            <Card
              Url={"/tour?id=" + row.id}
              Image={row.images[0]}
              Title={row.title}
              Description={row.description}
              Amount={row.price}
              Data={row}
              key={"iconsearch " + index + time}
            />
          </div>
        );
      });
      setCards(cardArray);
    }); //end handleAllTours
  };

  return (
    <>
      <section className="tour-specs-search">
        <figure className="bannercover"></figure>
        <div className="filter-icons">
          <div data-search={"featured"} onClick={handleiconClick}>
            <FaStar />
            <span>Featured</span>
          </div>

          <div data-search={"sight"} onClick={handleiconClick}>
            <FaEye />
            <span>Sight</span>
          </div>

          <div data-search={"smell"} onClick={handleiconClick}>
            <GiNoseFront />
            <span>Smell</span>
          </div>

          <div data-search={"taste"} onClick={handleiconClick}>
            <GiTongue />
            <span>Taste</span>
          </div>

          <div data-search={"hear"} onClick={handleiconClick}>
            <TbEar />
            <span>Sound</span>
          </div>

          <div data-search={"touch"} onClick={handleiconClick}>
            <TbHandClick />
            <span>Touch</span>
          </div>
        </div>
        <div className="search-container-search">
          <input
            placeholder="Search..."
            id="search"
            type="text"
            onChange={handleInputSearch}
          />
        </div>
        <span
          className="filter-clear unselectable"
          onClick={() => {
            window.location = "/tours";
          }}
        >
          Clear Filter
        </span>
      </section>
      <section className="alltours-container">{cards}</section>
    </>
  );
};

export default Alltours;
