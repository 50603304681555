import React, { useEffect, useRef, useState } from "react";
import "./calendar.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { AiFillCalendar } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { MdClear } from "react-icons/md";
import Stripe from "./stripe";
import * as EmailValidator from "email-validator";
import Cleave from "cleave.js/react";
import SignatureCanvas from "react-signature-canvas";
import style from "./signpad.module.css";

let isgroup = false;
const MyCaledar = (props) => {
  const { tourdata } = props;
  const [submittingCreditCard, setSubmittingCreditCard] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [creditcardtype, setCreditCardType] = useState("");
  const [formValidate, setFormValidate] = useState({ email: false });
  const [serviceFee, setServiceFee] = useState(0);
  const [dataComplete, setDataComplete] = useState(false);
  const [value, setValue] = useState(null);
  const [chosenDate, setChosenDate] = useState();
  const [chosenTime, setChosenTime] = useState();
  const [addedGuest, setAddedGuest] = useState();
  const [addedGuestMeta, setAddedGuestMeta] = useState();
  const [addedGuestObject, setAddedGuestObject] = useState([]);
  const [activeDays, setActiveDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [today, setToday] = useState(new Date());
  const signpadRef = useRef();
  const [termsChecked, setTermsChecked] = useState(false);
  const [dateTimes, setDateTimes] = useState({
    0: ["12:30 PM", "4:30 PM", "6:00 PM"],
    1: ["12:30 PM", "4:30 PM", "6:00 PM"],
    2: ["12:30 PM", "4:30 PM", "6:00 PM"],
    3: ["12:30 PM", "4:30 PM", "5:00 PM"],
    4: ["1:00 pm", "5:00pm"],
    5: ["8:00 PM"],
    6: ["6:00 PM"],
  });
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeContainer, setTimeContainer] = useState();
  const [guest, setGuest] = useState({
    rows: [
      {
        guest: "Adult",
        count: 0,
        price: 30,
        title: "$30.00 / person",
        total: 0,
      },
    ],
    totalCount: 0,
    totalAmount: 0,
    totalAmountFee: 0,
    maxCount: 20,
  });
  const [guestContainer, setGuestContainer] = useState();
  const [total, setTotal] = useState(0);

  const handleFormValidate = () => {
    return new Promise((resolve, reject) => {
      resolve(formValidate);
    });
  };

  const handleServiceFee = (fee) => {
    setServiceFee(fee);
  };

  useEffect(() => {
    if (tourdata.datetimes) {
      //alert(JSON.stringify(tourdata.datetimes));
      setValue(new Date());
      setTimeContainer();

      setDateTimes({});
      setTimeout(() => {
        setDateTimes(tourdata.datetimes);
      }, 100);

      setGuest((x) => {
        let guest = { ...x };
        guest.maxCount = tourdata.specs.size;
        guest.totalAmount = 0;
        guest.totalCount = 0;
        guest.totalAmountFee = 0;
        return guest;
      });

      if (tourdata.guest)
        setGuest((x) => {
          let guest = { ...x };
          guest.rows = tourdata.guest;
          return guest;
        });
    }
  }, [tourdata]);

  useEffect(() => {
    //setDateTimes(tourdata.datetimes);

    window.addEventListener("load", () => {
      const arrangeModal = () => {
        const root = document.getElementById("root");
        root.prepend(document.getElementsByClassName("booking-modal")[0]);
        const bookingContainer = document.getElementsByClassName(
          "booking-container"
        )[0];
        let bookingContainerHeight = bookingContainer.offsetHeight;
        const originWidgetContainer = document.getElementsByClassName(
          "origin-widget-container"
        )[0];

        originWidgetContainer.setAttribute(
          "style",
          "--height: " + (bookingContainerHeight - 100) + "px"
        );

        window.addEventListener("resize", () => {
          setTimeout(() => {
            bookingContainerHeight = bookingContainer.offsetHeight;
            originWidgetContainer.setAttribute(
              "style",
              "--height: " + (bookingContainerHeight - 100) + "px"
            );
          }, 10);
        });

        document.addEventListener("click", (event) => {
          const isClickInside = bookingContainer.contains(event.target);

          if (isClickInside) {
            setTimeout(() => {
              bookingContainerHeight = bookingContainer.offsetHeight;
              originWidgetContainer.setAttribute(
                "style",
                "--height: " + (bookingContainerHeight - 100) + "px"
              );
            }, 10);
            // The click was OUTSIDE the specifiedElement, do something
          }
        });
      };

      setTimeout(() => {
        arrangeModal();
      }, 100);
    });
  }, []);

  useEffect(() => {
    //console.log([...Object.keys(dateTimes)]);

    if (Object.keys(dateTimes).includes("all")) {
      setActiveDays([0, 1, 2, 3, 4, 5, 6, 7]);
      //apply key all to all dates
      setTimeSlots([]);
      setTimeout(() => {
        setTimeSlots(dateTimes.all);
      }, 100);
    } else {
      let activeDaysArray = [];
      Object.keys(dateTimes).map((x) => {
        activeDaysArray.push(parseInt(x));
      });
      setActiveDays(activeDaysArray);
    }
  }, [dateTimes]);

  useEffect(() => {
    let guestArray = [];
    guest.rows.map((x, index) => {
      guestArray.push(
        <div className="row">
          <div className="guest-name">{x.guest}</div>
          <div className="guest-controls">
            <button
              data-guest={index}
              onClick={() => handleGuestClick("remove", index)}
            >
              -
            </button>
            <span>{x.count}</span>
            <button
              data-guest={index}
              onClick={() => handleGuestClick("add", index)}
            >
              +
            </button>
          </div>
          <div className="guest-price">{x.title}</div>
        </div>
      );
    });

    setGuestContainer(guestArray);
  }, [guest]);

  useEffect(() => {
    setTimeout(() => {
      const originWidgetContainer = document.getElementsByClassName(
        "origin-widget-container"
      )[0];
      const bookingContainer = document.getElementsByClassName(
        "booking-container"
      )[0];
      let bookingContainerHeight = bookingContainer.offsetHeight;
      originWidgetContainer.setAttribute(
        "style",
        "--height: " + (bookingContainerHeight - 100) + "px"
      );
    }, 1000);
  }, guestContainer);

  useEffect(() => {
    let timesArray = [];
    timeSlots.map((x, index) => {
      timesArray.push(
        <div>
          <input type="radio" id={"time" + index} name={"time"} value={x} />
          <label htmlFor={"time" + index}>{x}</label>
        </div>
      );
    });

    setTimeContainer(timesArray);
  }, [timeSlots]);

  const handleGuestClick = (action, guestIndex) => {
    //console.log("ISGROUP:", isgroup);
    setGuest((g) => {
      let guestCopy = { ...g };
      //if(guestCopy.rows[0].groups)alert("sdf");
      guestCopy.rows.map((x, index) => {
        if (index == guestIndex) {
          if (action == "add" && guestCopy.totalCount < guestCopy.maxCount) {
            if (!x.groups) {
              guestCopy.rows.map((group, index) => {
                if (group.groups) {
                  //guestCopy.totalAmount = 0;
                  //guestCopy.totalAmountFee = 0;
                  if (group.count > 0) guestCopy.totalCount = 0;
                  group.count = 0;
                  group.total = 0;
                }
              });
            } else {
              guestCopy.rows.map((button, index) => {
                if (index != guestIndex) {
                  button.total = 0;
                  if (button.count > 0) guestCopy.totalCount = 0;
                  button.count = 0;
                }
              });
            } //end if group

            x.count++;
            guestCopy.totalCount++;
            if (x.groups) {
              isgroup = true;
              const xx = { ...x };
              if (x.count > xx.rates[xx.rates.length - 1].max) {
                x.count--;
              }
              xx.rates
                .slice(0)
                .reverse()
                .map((rate, index) => {
                  if (x.count <= rate.max && !(x.count > rate.max)) {
                    x.price = rate.price;
                    x.total = rate.price;
                    x.title = "$" + rate.price.toFixed(2) + " / group";
                    guestCopy.totalAmount = rate.price;
                    guestCopy.totalAmountFee = rate.price;
                  }
                });
            } //end if groups
            else {
              isgroup = false;
            }
          }
          if (action == "remove" && x.count > 0) {
            x.count--;
            guestCopy.totalCount--;
            if (x.groups) {
              isgroup = true;
              const xx = { ...x };

              xx.rates
                .slice(0)
                .reverse()
                .map((rate, index) => {
                  if (x.count <= rate.max && !(x.count > rate.max)) {
                    x.price = rate.price;
                    x.total = rate.price;
                    x.title = "$" + rate.price.toFixed(2) + " / group";
                    guestCopy.totalAmount = rate.price;
                    guestCopy.totalAmountFee = rate.price;
                    if (x.count <= 0) {
                      guestCopy.totalAmount = 0;
                      guestCopy.totalAmountFee = 0;
                    }
                  }
                });
            } //end if groups
            else {
              isgroup = false;
            }
          }
          if (!isgroup) x.total = x.count * x.price;
          //x.total = x.count * x.price;
        }
      });

      if (!isgroup) {
        let total = 0;
        guestCopy.rows.map((x) => {
          total += x.total;
        });
        guestCopy.totalAmount = total;
        guestCopy.totalAmountFee = total;
      }
      return guestCopy;
    });
  }; //end handleGuestClick

  const getFormatDate = (day) => {
    const today = new Date(day);

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    //return (yyyy + "-" + mm + "-" + dd);
    return mm + "/" + dd + "/" + yyyy;
  };

  const setDate = (datevalue) => {
    const newDate = new Date(datevalue);
    //alert(getFormatDate(newDate));

    const dateNum = newDate.getDay();

    if (
      Object.keys(dateTimes).includes(dateNum.toString()) &&
      !Object.keys(dateTimes).includes("all")
    ) {
      //console.log("Date: ",JSON.stringify(Object.keys(dateTimes)));
      console.log(dateTimes[dateNum]);
      setTimeSlots([]);
      setTimeout(() => {
        setTimeSlots(dateTimes[dateNum]);
      }, 100);
    }
    setChosenDate(getFormatDate(newDate));
    setValue(datevalue);
  };

  const handleBookingClick = (close = false) => {
    const time = document.querySelector('input[name="time"]:checked')
      ? document.querySelector('input[name="time"]:checked').value
      : null;

    setChosenTime(time);
    let addedGuestArray = [];
    let addedGuestMeta = [];
    let addedGuestObjectArray = [];
    guest.rows.map((x, index) => {
      if (x.count > 0) {
        addedGuestObjectArray.push({
          guestname: x.guest,
          count: x.count,
          price: x.total,
        });
        addedGuestMeta.push(
          x.guest + " x " + x.count + " - Total: " + x.total.toFixed(2)
        );
        addedGuestArray.push(
          <div className="row">
            <div className="left">
              <FaUserAlt />
              <span>{x.guest + " x " + x.count}</span>
            </div>
            <div className="right">${x.total.toFixed(2)}</div>
          </div>
        );
      }
    });

    setAddedGuestObject(addedGuestObjectArray);
    setAddedGuestMeta(addedGuestMeta);
    setAddedGuest(addedGuestArray);

    if (time && chosenDate && guest.totalAmount > 0 && guest.totalCount > 0) {
      setDataComplete(true);
      const rootChildren = [...document.getElementById("root").children];
      rootChildren.map((x) => {
        if (
          x.getAttribute("class") != "booking-modal" &&
          !x.getAttribute("class").includes("confirmation-modal")
        )
          x.classList.add("hideelement");
      });
      document.getElementsByClassName("booking-modal")[0].style.display =
        "flex";

      window.scrollTo(0, 0);
      window.location = "#checkout";
    } //end if
  };

  const handleCloseBooking = () => {
    setDataComplete(false);
    const rootChildren = [...document.getElementById("root").children];
    rootChildren.map((x) => {
      x.classList.remove("hideelement");
    });

    document.getElementsByClassName("booking-modal")[0].style.display = "none";

    setTimeout(() => {
      const originWidgetContainer = document.getElementsByClassName(
        "origin-widget-container"
      )[0];
      const bookingContainer = document.getElementsByClassName(
        "booking-container"
      )[0];
      let bookingContainerHeight = bookingContainer.offsetHeight;
      originWidgetContainer.setAttribute(
        "style",
        "--height: " + (bookingContainerHeight - 100) + "px"
      );
    }, 10);
  };

  const creditcardRef = useRef();
  function onCreditCardTypeChanged(type) {
    if (type.toUpperCase() == "UNKNOWN") {
      setCreditCardType("");
    } else {
      setCreditCardType(type);
    }
  } //end onCreditCardTypeChange

  const submitCreditCard = async () => {
    const checkfields = {
      firstname: document.getElementById("firstname").value,
      lastname: document.getElementById("lastname").value,
      email: document.getElementById("email").value,
      phone: document.getElementById("phone").value,
      creditcard: document.getElementById("creditcard").value,
      expdate: document.getElementById("creditcard-date").value,
      security: document.getElementById("creditcard-security").value,
      zip: document.getElementById("creditcard-zip").value,
    };

    let emptyFields = false;
    Object.keys(checkfields).map((x) => {
      if (checkfields[x] == "") {
        emptyFields = true;
      }
    });

    if (signpadRef.current) {
      const isEmpty = signpadRef.current.isEmpty();
      if (!isEmpty && !emptyFields) {
        setSubmittingCreditCard(true);
        const signature = signpadRef.current
          .getTrimmedCanvas()
          .toDataURL("image/png");

        const fields = {
          firstname: document.getElementById("firstname").value,
          lastname: document.getElementById("lastname").value,
          email: document.getElementById("email").value,
          phone: document.getElementById("phone").value,
          creditcard: document.getElementById("creditcard").value,
          expdate: document.getElementById("creditcard-date").value,
          security: document.getElementById("creditcard-security").value,
          zip: document.getElementById("creditcard-zip").value,
          signature,
          type: creditcardtype,
        };

        const apiURL = "https://api.smellsandsounds.co.uk/enquire";
        //const apiURL = "http://localhost:3003/enquire"; //for local testing
        const results = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            date: chosenDate,
            time: chosenTime,
            row: addedGuestObject,
            total: guest.totalAmountFee.toFixed(2),
            title:
              tourdata.title +
              (tourdata.packagetitle
                ? " (" + tourdata.packagetitle + ") "
                : ""),
            fields,
          }),
        }).catch();

        const json = await results.json();
        if (json.success === "success") {
          //window.location = "/";
          const page = json.confirmationPage;
          const bookingModal = document.getElementsByClassName(
            "booking-modal"
          )[0];
          bookingModal.style.display = "none";

          const modal = document.getElementById("confirmation-modal");
          modal.getElementsByClassName("body")[0].innerHTML = page;

          modal.classList.remove("confirmation-modal-hidden");
          window.scrollTo(0, 0);
        }
      } //signature not empty
    }
  };

  useEffect(() => {
    if (termsChecked) signpadRef.current._resizeCanvas();
  }, [termsChecked]);
  return (
    <>
      <div className="booking-container">
        <header>
          <Calendar
            onClickDay={(v) => setDate(v)}
            tileDisabled={({ activeStartDate, date, view }) =>
              (view === "month" && date <= today) ||
              !activeDays.includes(date.getDay())
            }
          />
        </header>
        <div className="time-container">
          <h3>Pick a Time</h3>
          <div className="times-available">{timeContainer}</div>
        </div>
        <div className="pricing-container">
          <header>
            ${guest.totalAmount.toFixed(2)}
            <span className="smalltext">Total</span>
          </header>
          <div className="guest-container">{guestContainer}</div>
        </div>

        <footer>
          <span>Price exclusive of all taxes and fees</span>
          <button
            onClick={() => {
              handleBookingClick();
            }}
          >
            Book
          </button>
        </footer>
      </div>

      <div className="booking-modal">
        <BiArrowBack
          onClick={() => {
            handleCloseBooking();
          }}
        />
        <div className="customer-info">
          <h4>
            {"Reserve " +
              tourdata.title +
              (tourdata.packagetitle
                ? " (" + tourdata.packagetitle + ") "
                : "") +
              " with Smells and Sounds"}
          </h4>
          <h3>Contact Information</h3>
          <div className="row">
            <div className="column">
              <label>*First Name</label>
              <input
                id="firstname"
                type="text"
                onFocus={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                }}
              ></input>
            </div>
            <div className="column">
              <label>*Last Name</label>
              <input
                id="lastname"
                type="text"
                onFocus={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                }}
              ></input>
            </div>
          </div>
          <div className="row">
            <label>*Email</label>
            <input
              id="email"
              type="text"
              onBlur={(e) => {
                if (!EmailValidator.validate(e.target.value)) {
                  e.target.style.backgroundColor = "#f4c3c3";
                }
                setFormValidate((x) => {
                  let xx = { ...x };
                  xx.email = EmailValidator.validate(e.target.value);
                  return xx;
                });
              }}
              onFocus={(e) => {
                e.currentTarget.style.backgroundColor = "white";
              }}
            ></input>
          </div>
          <div className="row">
            <label>*Phone</label>
            <input
              id="phone"
              type="text"
              onFocus={(e) => {
                e.currentTarget.style.backgroundColor = "white";
              }}
            ></input>
          </div>

          <div className="row-creditcard">
            <span className="creditcardtype">{creditcardtype}</span>
            <h3>Card Details</h3>
            <Cleave
              className="creditcard"
              options={{ creditCard: true, onCreditCardTypeChanged }}
              placeholder="Enter credit card number"
              id="creditcard"
            />
            <div className="creditcard-security">
              <Cleave
                placeholder="MM/YY"
                options={{ date: true, datePattern: ["m", "d"] }}
                className="date"
                id="creditcard-date"
              />
              <Cleave
                placeholder="CVV"
                options={{
                  blocks: [4],
                  numericOnly: true,
                }}
                className="cvv"
                id="creditcard-security"
              />
              <Cleave
                placeholder="Zip"
                options={{
                  blocks: [5],
                  numericOnly: true,
                }}
                className="zip"
                id="creditcard-zip"
              />
            </div>
          </div>

          <div className="row">
            <span style={{ fontSize: "12px" }}>
              By clicking this box I understand that I will be charged $75 for
              each tour that I have selected on The Smells and Sounds LLC
              website. This is a fee paid to The Smells and Sounds of London LLC
              for processing and reserving my selected tour(s). This fee is
              non-refundable. This fee does not include the cost of my tour(s)
              themselves as these will be charged to my card separately and
              directly from the tour provider themselves.
            </span>
          </div>

          <div className="row-checkbox">
            <input
              onChange={(e) => {
                setTermsChecked(e.target.checked);
              }}
              type="checkbox"
              name="terms"
              id="chk-terms"
            ></input>
            <label htmlFor="chk-terms">
              I accept the{" "}
              <a href="/terms" target="_blank">
                terms & conditions
              </a>
              {tourdata.policy && (
                <>
                  <span> and </span>
                  <a
                    onClick={() => {
                      setShowPolicy(true);
                    }}
                    className={"tour-policy-link"}
                    href="#!"
                  >
                    policies
                  </a>
                </>
              )}
            </label>
          </div>
          {tourdata.policy && showPolicy && (
            <div className="row-checkbox">
              <div className="policy-container">
                <h5>Refund/Cancellation Policy</h5>
                <span>{tourdata.policy}</span>
              </div>
            </div>
          )}

          {termsChecked && (
            <div className="row down">
              <h4>Provide signature for terms and conditions</h4>
              <div className={style.signpadcontainer}>
                <MdClear
                  onClick={() => {
                    signpadRef.current.clear();
                  }}
                  className={style.clear}
                />
                <SignatureCanvas
                  ref={signpadRef}
                  penColor="black"
                  canvasProps={{
                    className: style.signpad,
                  }}
                />
              </div>
            </div>
          )}
          <div className="row">
            <button
              disabled={submittingCreditCard}
              onClick={() => {
                submitCreditCard();
              }}
            >
              {submittingCreditCard ? "...sending" : "Book"}
            </button>
          </div>

          {/*
          //remove, only need enquire button
          //-----------------------------
          <div className="row">
            {dataComplete && (
              <Stripe
                title={
                  tourdata.title +
                  (tourdata.packagetitle
                    ? " (" + tourdata.packagetitle + ") "
                    : "")
                }
                chosenDate={chosenDate}
                chosenTime={chosenTime}
                addedGuestMeta={addedGuestMeta}
                total={guest.totalAmountFee.toFixed(2)}
                handleServiceFee={handleServiceFee}
                addedGuestObject={addedGuestObject}
                handleFormValidate={handleFormValidate}
              />
            )}
          </div>
          */}
        </div>
        <div className="booking-info">
          <div className="info-box">
            <header>
              <img src="/android-chrome-512x512.png"></img>
            </header>
            <div className="booking-body">
              <div className="row">
                <div className="left">
                  <AiFillCalendar />
                  <span>{chosenDate + " at " + chosenTime}</span>
                </div>
              </div>

              {addedGuest}

              <div className="row">
                <div className="left">
                  <span>Service Fee</span>
                </div>
                <div className="right">$75.00</div>
              </div>
            </div>

            <footer>
              <div className="row">
                <div className="left">
                  <span>Total</span>
                </div>
                <div className="right">
                  ${(guest.totalAmountFee + 75).toFixed(2)}
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCaledar;
