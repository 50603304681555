import React, { Component, useEffect, useRef, useState } from "react";
import "./feature.css";
import { FaStar } from "react-icons/fa";
//import { FolderSearch } from "./cloudinary/search";

function Feature(props) {
  const { Image, Title, Description, Amount, Url } = props;
  const [cldImage, setcldImage ] = useState();
  useEffect(() => {
    /*
    (async () => {
      const images = await FolderSearch(Title);
      if (images.length > 0) {
        setcldImage(images[0]);
      }
    })();
    */
  }, []);
  const handleClick = () => {
    //window.open(Url, "_blank").focus();
    window.location = Url;
  };
  return (
    <>
      <div className="feature-card">
        <div
          className="feature-image"
          onClick={handleClick}
          style={{ backgroundImage: "URL(" + Image + ")" }}
        >
          <span>More Info</span>
        </div>
        <div className="feature-title">{Title}</div>
        <div className="feature-description">
          <p>{Description}</p>
        </div>
        <div className="feature-footer">
          <span className="feature-price">{Amount}</span>
          <div className="ratings-container">
            <FaStar className="FaStar" />
            <FaStar className="FaStar" />
            <FaStar className="FaStar" />
            <FaStar className="FaStar" />
            <FaStar className="FaStar" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Feature;
