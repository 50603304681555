import "./tour-page.css";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiMapPin2Fill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { BsFillClockFill } from "react-icons/bs";
import { AiFillDollarCircle, AiOutlineClose } from "react-icons/ai";
import { BiMessageEdit } from "react-icons/bi";
import Feature from "./feature";
import "animate.css";
import { FaStar } from "react-icons/fa";
//import { FolderSearch } from "./cloudinary/search";
import MyCaledar from "./calendar-enquire";
import Timeline from "./timeline";

import Ratings from "./rating";
//-----------Headers----------------

function TourPage(props) {
  const { tourdata, handleTourPage, handleReviewSearch } = props;
  const [tourPackages, settourPackages] = useState();
  const [_tourdata, _setTourData] = useState([]);
  const [cldImage, setcldImage] = useState();
  const [specs, setSpecs] = useState({});
  const [itinarary, setItinarary] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [calendar, setCalendar] = useState();

  const [callbackdata, setCallbackdata] = useState();
  const slider1 = useRef();
  const slider2 = useRef();

  const [images, setImages] = useState([]);
  const [imagesNoclick, setImagesNoclick] = useState([]);
  const [widget, setWidget] = useState();
  const [time, setTime] = useState("");
  const [reviews, setReviews] = useState();
  const [ratingDisplay, setRatingDisplay] = useState("none");

  const [locationAnimation, setLocationAnimation] = useState(
    "animate__animated animate__heartBeat"
  );

  const addMeta = (property, content) => {
    const meta = document.createElement("meta");
    meta.setAttribute("property", property);
    meta.setAttribute("content", content);

    document.getElementsByTagName("head")[0].prepend(meta);
  }; //end addMeta

  const getReviews = (id) => {
    handleReviewSearch(id, (data) => {
      let reviewList = [];
      data.map((x, index) => {
        reviewList.push(
          <div
            className="customer-review"
            style={(() => {
              if (x.description)
                if (x.description.length > 300) {
                  return { maxHeight: "200px" };
                }
            })()}
          >
            <span
              onClick={(e) => {
                e.target.parentElement.style.maxHeight = "100%";
                e.target.style.display = "none";
              }}
              className="expand"
              style={(() => {
                if (x.description)
                  if (x.description.length > 300) {
                    return { display: "block" };
                  } else {
                    return { display: "none" };
                  }
              })()}
            >
              Read more
            </span>
            <header>
              <span>{x.name}</span>
              <div>
                {(() => {
                  let starArray = [];
                  for (let index = 0; index < x.numStars; index++) {
                    starArray.push(<FaStar />);
                  }
                  return starArray;
                })()}
              </div>
            </header>
            <div className="subheader">
              {(() => {
                if (x._date) {
                  return x._date;
                } else {
                  const xDate = new Date(x.created);
                  const dateString =
                    xDate.getMonth() +
                    1 +
                    "/" +
                    xDate.getDate() +
                    "/" +
                    xDate.getFullYear();
                  return dateString;
                }
              })()}
            </div>
            <div className="description">{x.description}</div>
          </div>
        );
      });

      let reviewContainer = (
        <div className="customer-review-container">
          <h2>Reviews</h2>
          <div className="customer-review-flex">{reviewList}</div>
        </div>
      );
      setReviews(reviewContainer);
    });
  };

  let timesRan = 0;
  useEffect(() => {
    /*
    //console.log(JSON.stringify(_tourdata.datetimes));
    setCalendar(null);
    setTimeout(() => {
      setCalendar(
        <MyCaledar key={"calendar-" + timesRan} tourdata={_tourdata} />
      );
    }, 100);
    timesRan++;
    */
  }, [_tourdata]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    handleTourPage(id, (data) => {});
    getReviews(id);
    window.addEventListener("load", () => {
      const slider = document.getElementsByClassName("tour-image-slider")[0];
      const images = [...slider.getElementsByTagName("img")];

      const modalTimer = setInterval(() => {
        if (document.getElementById("origin-modal")) {
          clearInterval(modalTimer);

          const modalsOrigin = [
            ...document.querySelectorAll("[id='origin-modal']"),
          ];
          console.log("FOund");
        }
      }, 100);
    });

    window.addEventListener("message", (event) => {
      //console.log("DATA", event);
      const themodal = document.getElementById("origin-modal")
        ? document.getElementById("origin-modal")
        : null;
      //
      const data = event.data;
      if (data.func)
        if (data.func == "openOriginModal") if (themodal) themodal.remove();
      if (typeof window[data.func] == "function") {
        var body = document.getElementsByTagName("body")[0];
        var modal = document.createElement("div");
        modal.id = "origin-modal";
        modal.innerHTML = `
          <div aria-hidden="false" class="micromodal-slide schedule-modal is-open" id="checkout-modal">
            <div class="modal__overlay" tabindex="-1" style="z-index: 999999999">
              <div aria-labelledby="modal-1-title" aria-modal="true" class="modal__container" role="dialog">
                <div class="modal__header">
                  <h2 class="modal__title">
                  </h2>
                  <div aria-label="Close modal" class="modal__close" data-micromodal-close></div>
                </div>
                <div class="modal__content">
                  <!--<img src="https://www.exploreorigin.com/origin-loading.gif" id="origin-loading">-->
                  <iframe frameBorder="0" id="checkout-iframe" src="$data.message" style="width: 100%; height: 700px;" title="Schedule with Origin"></iframe>
                </div>
              </div>
            </div>
          </div>
        `;

        body.after(modal);

        setTimeout(() => {
          //window[data.func].call(null, data.message);
        }, 500);
      }
    });
  }, []);

  useEffect(() => {
    const d = new Date();
    let time = d.getTime();
    setTime(time);
    if (!tourdata) window.location = "/";
    /*
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    handleTourPage(id);
*/
    _setTourData(tourdata);
    setSpecs(tourdata.specs);

    var thumbnailSlides = document.querySelectorAll(
      ".tour-image-thumbnail .slick-slide"
    );

    thumbnailSlides.forEach((element) => {
      element.addEventListener("click", (e) => {
        var thumbnailSlidesCurrent = document.querySelectorAll(
          ".tour-image-thumbnail .slick-current"
        );
        thumbnailSlidesCurrent.forEach((slide) => {
          slide.classList.remove("slick-current");
          console.log(slide);
        });

        var slide = e.target.parentElement.parentElement;
        slide.classList.add("slick-current");
        var index = slide.getAttribute("data-index");
        slider2.current.slickGoTo(index);
      });
    });

    document
      .getElementsByClassName("bottom")[0]
      .scrollIntoView({ behavior: "smooth" });

    var script = document.createElement("script");
    script.src = "https://www.exploreorigin.com/widget_v2.js";
    script.setAttribute("data-listing", tourdata.originID);
    var origincontainer = document.getElementsByClassName(
      "origin-widget-container"
    )[0];

    if (tourdata.id != undefined) {
      //if (tourdata.policy) alert(tourdata.policy);
      //origincontainer.innerHTML = "";
      //origincontainer.appendChild(script);
      //console.log("Added");
      //control origin iframe and it's container height based on the amount of hour slots available

      const iframeTimer = setInterval(() => {
        clearInterval(iframeTimer);
        if (document.getElementById("booking-iframe")) {
          //clearInterval(iframeTimer);

          const origin_iframe = document.getElementById("booking-iframe");

          const origin_container = document.getElementsByClassName(
            "origin-widget-container"
          )[0];

          const slotCount = tourdata.slotCount;
          const fixedHeight =
            slotCount == 1
              ? 725
              : slotCount == 2
              ? 816
              : slotCount == 3
              ? 882
              : slotCount == 4
              ? 902
              : 700;
          //const initialHeight = slotCounts > 1 ? 805 : 700;
          //let originHeight = initialHeight + 25 * slotCounts;
          const originContainerHeight = fixedHeight - 115;

          origin_iframe.style.height = fixedHeight + "px";
          origin_container.setAttribute(
            "style",
            "--height: " + originContainerHeight + "px;"
          );
        } //end if iframe Found
      }, 100);

      //add metas

      addMeta("og:locale", "em_US");
      addMeta("og:type", "website");
      addMeta("og:title", tourdata.title);
      addMeta("og:description", tourdata.description);
      addMeta("og:image", tourdata.images[0]);

      /*
      (async () => {
        const images = await FolderSearch(tourdata.title);
        console.log(images);
        let newImageArray = [];
        if (images.length > 0) {
          images.map((url) => {
            console.log(url);
            newImageArray.push(<img className="tour-slide" src={url}></img>);
          });
          setcldImage(newImageArray);
        }
      })();
      */

      //get itinarary and highlights

      if (tourdata.itinarary)
        if (tourdata.itinarary.length > 0) setItinarary(tourdata.itinarary);

      let highlightArray = [];
      if (tourdata.highlights)
        tourdata.highlights.map((x, index) => {
          highlightArray.push(<li>{x}</li>);
        });
      setHighlights(highlightArray);

      if (tourdata.packages)
        if (tourdata.packages.length > 0) {
          //set the packagetitle if not empty
          _setTourData((x) => {
            let copy = { ...x };
            copy.packagetitle =
              tourdata.packages[0].title != "" && tourdata.packages[0].title;
            return copy;
          });
          let packagesarray = [];
          let defaultSpec = {
            price: tourdata.price,
            title: tourdata.packages[0].title,
            duration: tourdata.specs.duration,
            originID: tourdata.originID,
            slotCount: tourdata.slotCount,
            description: "",
            guest: tourdata.guest,
            datetimes: tourdata.datetimes,
          };
          //set Standard--------------------------------
          packagesarray.push(
            <div
              className="tour-option chosen"
              data-set={JSON.stringify(defaultSpec)}
              onClick={updateOption}
            >
              <div className="timeline__event__icon option-icon">
                <div className="timeline__event__date option-text">
                  {tourdata.packages[0].title != ""
                    ? tourdata.packages[0].title
                    : "Standard"}
                </div>
              </div>
              <div className="timeline__event__content ">
                <div className="timeline__event__title">
                  {tourdata.packages[0].duration +
                    " - " +
                    tourdata.packages[0].price}
                </div>
                <div className="timeline__event__description">
                  <p>{tourdata.packages[0].description}</p>
                </div>
              </div>
            </div>
          );
          //set Standard---------------------------------
          tourdata.packages.map((x, index) => {
            if (index != 0)
              packagesarray.push(
                <div
                  className="tour-option"
                  data-set={JSON.stringify(x)}
                  onClick={updateOption}
                >
                  <div className="timeline__event__icon option-icon">
                    <div className="timeline__event__date option-text">
                      {x.title}
                    </div>
                  </div>
                  <div className="timeline__event__content ">
                    <div className="timeline__event__title">
                      {x.duration + " - " + x.price}
                    </div>
                    <div className="timeline__event__description">
                      <p>{x.description}</p>
                    </div>
                  </div>
                </div>
              );
          });

          let packagescontainer = (
            <div className="tour-options-container add-bottom-border">
              <h2>Package Options</h2>
              <div className="options-container">{packagesarray}</div>
            </div>
          );

          settourPackages(packagescontainer);
        } //end if packages
    } //end if tourdata.id
  }, [tourdata]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: (current, next) => {
      //console.log(next);
      var thumbnailSlides = document.querySelectorAll(
        ".tour-image-thumbnail .slick-current"
      );
      thumbnailSlides.forEach((slide) => {
        slide.classList.remove("slick-current");
      });
      slider1.current.slickGoTo(next);
    } /*
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: "0",
          slidesToShow: 1,
          fade: false,
        },
      },
    ],*/,
  };

  const settingsThumbnail = {
    vertical: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    //focusOnSelect: true,
    beforeChange: (current, next) => {
      slider2.current.slickGoTo(next);
      //console.log(current);
    },
  };

  const testArray = [
    "https://images.pexels.com/photos/15188442/pexels-photo-15188442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/7615255/pexels-photo-7615255.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/7889450/pexels-photo-7889450.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/13211456/pexels-photo-13211456.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  ];

  const sliderFullScreen = () => {
    const slider = document.getElementsByClassName("tour-image-slider")[0];
    if (document.getElementsByClassName("slider-fullscreen").length == 0) {
      slider.classList.add("slider-fullscreen");
      /*
      const meta = [...document.getElementsByTagName("meta")];
      meta.map((x)=>{
        if(x.name == "viewport"){
          x.setAttribute("content", "width=device-width, initial-scale=1, user-scalable=yes")
        }
      })
      */
      // const close = document.createElement("span");
      //close.innerText = "X";
      //close.classList.add("slider-close");
      //slider.appendChild(close);
    } else {
      slider.classList.remove("slider-fullscreen");
    }
  };

  const getImages = (hasClick = true) => {
    let newImageArray = [];
    let noClickImageArray = [];

    if (tourdata.images.length > 0)
      tourdata.images.forEach((url) => {
        newImageArray.push(
          <img
            onClick={() => {
              sliderFullScreen();
            }}
            className="tour-slide"
            src={url}
          ></img>
        );

        noClickImageArray.push(<img className="tour-slide" src={url}></img>);
      });

    //setImages(newImageArray);
    //setImagesNoclick(noClickImageArray);
    if (hasClick) {
      return newImageArray;
    } else {
      return noClickImageArray;
    }
  }; //end getImages

  const updateOption = (e) => {
    const element = e.currentTarget;
    if (!element.getAttribute("class").includes("chosen")) {
      document.getElementsByClassName("chosen")[0].classList.remove("chosen");
      element.classList.add("chosen");
      const json = JSON.parse(element.getAttribute("data-set"));
      //let newSpecs = { ...specs };
      //console.log("NEW SPECS: ", newSpecs);
      //let newTourData = { ..._tourdata };
      //newSpecs.duration = json.duration;
      //newTourData.price = json.price;

      //setSpecs(newSpecs);
      setSpecs((x) => {
        let copySpecs = { ...x };
        copySpecs.duration = json.duration;
        return copySpecs;
      });
      //_setTourData(newTourData);
      _setTourData((x) => {
        let copyData = { ...x };
        copyData.price = json.price;
        copyData.packagetitle = json.title;
        if (json.datetimes)
          if (
            Object.keys(json.datetimes).length > 0 &&
            typeof json.datetimes != "string"
          ) {
            copyData.datetimes = json.datetimes;
          }

        if (json.guest)
          if (json.guest.length > 0 && typeof json.guest != "string")
            copyData.guest = json.guest;
        return copyData;
      });

      /*
      const themodal = document.getElementById("origin-modal")
        ? document.getElementById("origin-modal")
        : null;

      if (themodal) themodal.remove();

      var script = document.createElement("script");
      script.src = "https://www.exploreorigin.com/widget_v2.js";
      script.setAttribute("data-listing", json.originID);
      var origincontainer = document.getElementsByClassName(
        "origin-widget-container"
      )[0];
      origincontainer.innerHTML = "";
      origincontainer.appendChild(script);

      const iframeTimer = setInterval(() => {
        if (document.getElementById("booking-iframe")) {
          clearInterval(iframeTimer);

          const origin_iframe = document.getElementById("booking-iframe");
          //console.log(origin_iframe);
          const origin_container = document.getElementsByClassName(
            "origin-widget-container"
          )[0];

          const slotCount = json.slotCount;
          const fixedHeight =
            slotCount == 1
              ? 725
              : slotCount == 2
              ? 816
              : slotCount == 3
              ? 882
              : slotCount == 4
              ? 902
              : 700;
          //const initialHeight = slotCounts > 1 ? 805 : 700;
          //let originHeight = initialHeight + 25 * slotCounts;
          const originContainerHeight = fixedHeight - 115;

          origin_iframe.style.height = fixedHeight + "px";
          origin_container.setAttribute(
            "style",
            "--height: " + originContainerHeight + "px;"
          );
        } //end if iframe Found
      }, 100);
      */
    }
  }; //end updateOption

  return (
    <>
      <section className="tour-specs">
        <figure className="bannercover"></figure>
        <span>{tourdata ? tourdata.title : "No Tour Found"}</span>
        <div className="thumbnail-container">
          <div
            className="location"
            onClick={() => {
              window.open(
                "https://www.google.com/maps/place/" +
                  encodeURIComponent(
                    _tourdata.specs.address
                      ? _tourdata.specs.address
                      : _tourdata.specs.location
                  ),
                "_blank"
              );
              setLocationAnimation("animate__animated");
            }}
          >
            <div className="icon">
              <RiMapPin2Fill className={locationAnimation} />
              <span>Location</span>
            </div>
            <div className="description">{specs.location}</div>
          </div>
          <div className="Group Size">
            <div className="icon">
              <FaUserAlt />
              <span>Group Size</span>
            </div>
            <div className="description">Max {specs.size} people</div>
          </div>
          <div className="Duration">
            <div className="icon">
              <BsFillClockFill />
              <span>Duration</span>
            </div>
            <div className="description">{specs.duration}</div>
          </div>

          <div className="cost">
            <div className="icon">
              <AiFillDollarCircle />
              <span>Cost</span>
            </div>
            <div className="description">{_tourdata.price}</div>
          </div>
        </div>
      </section>
      <section className="tour-container">
        <div className="tour-info-column">
          <div className="tour-image-container">
            <Slider
              className="tour-image-thumbnail"
              ref={slider1}
              {...settingsThumbnail}
            >
              {getImages(false)}
            </Slider>

            <Slider ref={slider2} className="tour-image-slider" {...settings}>
              {getImages(true)}
            </Slider>
          </div>
          <div className="tour-description-container add-bottom-border">
            <div className="tour-title">
              {tourdata ? tourdata.title : "No Tour Found"}
            </div>
            <div
              className="tour-description"
              dangerouslySetInnerHTML={
                tourdata && { __html: tourdata.description }
              }
            >
              {/*tourdata ? tourdata.description : "Please try another search"*/}
            </div>
            <div className="tour-price">{tourdata ? tourdata.price : ""}</div>
          </div>
          {tourPackages}
          <div
            className="timeline-container add-bottom-border"
            style={{ display: itinarary.length > 0 ? "block" : "none" }}
          >
            <h2>Itinerary</h2>
            <Timeline key={"timeline-" + time} itinarary={tourdata.itinarary} />
          </div>
          <div
            className="tour-highlights add-bottom-border"
            style={{ display: highlights.length > 0 ? "block" : "none" }}
          >
            <h2>Highlights</h2>
            <ul>{highlights}</ul>
          </div>

          <Ratings
            getReviews={getReviews}
            TourID={_tourdata.id}
            display={ratingDisplay}
            handleClose={() => {
              setRatingDisplay("none");
            }}
          />
          {reviews}
          <div className="review-btn-container">
            <span
              onClick={() => {
                setRatingDisplay("flex");
              }}
            >
              <BiMessageEdit /> Write a review
            </span>
          </div>
        </div>
        <div className="origin-widget-container">
          {_tourdata && <MyCaledar tourdata={_tourdata} />}
        </div>
      </section>
      <section
        id="confirmation-modal"
        className="confirmation-modal confirmation-modal-hidden animate__animated animate__slideInUp"
      >
        <header>
          <AiOutlineClose
            onClick={() => {
              window.location = "/";
            }}
          />
        </header>
        <div className="body"></div>
      </section>
    </>
  );
}

export default TourPage;
