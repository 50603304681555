import React, { useMemo, useState, useEffect } from "react";
import "./stripe.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_CRojhEdPA8PufsCQL8PJ7ezC00AbdhgdrD");

const Stripe = (props) => {
  const {
    chosenDate,
    chosenTime,
    addedGuestMeta,
    total,
    handleServiceFee,
    title,
    addedGuestObject,
    handleFormValidate,
  } = props;
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [_confirmationcode, setConfirmationcode] = useState("");
  const [clientSecret, setClientScret] = useState("");

  const useOptions = () => {
    //const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: "20px",
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#9e2146",
          },
        },
      }),
      []
    );

    return options;
  };

  useEffect(() => {
    fetch("https://api.smellsandsounds.co.uk/stripe/config")
      .then(async (r) => {
        const { publishableKey, serviceFee } = await r.json();
        setStripePromise(loadStripe(publishableKey));
        handleServiceFee(serviceFee);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");
    myHeaders.append("Content-Type", "application/json");
    //if (dataComplete)
    fetch("https://api.smellsandsounds.co.uk/stripe/create-payment-intent", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        chosenDate,
        chosenTime,
        addedGuestMeta,
        total,
        title,
      }),
    })
      .then(async (r) => {
        const { clientSecret, confirmationcode } = await r.json();
        setClientScret(clientSecret);
        setConfirmationcode(confirmationcode);
      })
      .catch((err) => {});
  }, []);

  const postSuccess = () => {
    return new Promise((resolve, reject) => {
      const fields = {
        firstname: document.getElementById("firstname").value,
        lastname: document.getElementById("lastname").value,
        email: document.getElementById("email").value,
        phone: document.getElementById("phone").value,
      };

      var myHeaders = new Headers();
      myHeaders.append("pragma", "no-cache");
      myHeaders.append("cache-control", "no-cache");
      myHeaders.append("Content-Type", "application/json");
      //if (dataComplete)
      fetch("https://api.smellsandsounds.co.uk/payment/success", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          date: chosenDate,
          time: chosenTime,
          row: addedGuestObject,
          total,
          title,
          fields,
          code: _confirmationcode,
        }),
      })
        .then(async (r) => {
          const { success } = await r.json();
          resolve(success);
        })
        .catch((err) => {});
    }); //
  }; //end postSuccess

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async (event) => {
      event.preventDefault();

      const fields = {
        firstname: document.getElementById("firstname"),
        lastname: document.getElementById("lastname"),
        email: document.getElementById("email"),
        phone: document.getElementById("phone"),
      };
      const fieldsSet =
        fields.firstname.value != "" &&
        fields.lastname.value != "" &&
        fields.email.value != "" &&
        fields.phone.value != ""
          ? true
          : false;

      const formValidate = await handleFormValidate();
      let formReady = true;
      Object.keys(formValidate).map((x) => {
        if (!formValidate[x]) formReady = false;
      });

      if (elements == null || !fieldsSet || !formReady) {
        Object.keys(fields).map((x) => {
          if (fields[x].value == "") {
            fields[x].style.backgroundColor = "#ffd0d0bf";
          }
        });
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name:
                document.getElementById("firstname").value +
                " " +
                document.getElementById("lastname").value,
              phone: document.getElementById("phone").value,
              email: document.getElementById("email").value,
            },
          },
        }
      );

      if (error) {
        setMessage(error.message);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        setMessage("Payment Status: " + paymentIntent.status);

        //take customer to confirmation page
        const page = await postSuccess();

        const bookingModal = document.getElementsByClassName(
          "booking-modal"
        )[0];
        bookingModal.style.display = "none";

        const modal = document.getElementById("confirmation-modal");
        modal.getElementsByClassName("body")[0].innerHTML = page;
        modal.classList.remove("confirmation-modal-hidden");
        Object.keys(fields).map((x) => {
          fields[x].value = "";
        });
        setTimeout(() => {
          //window.location = "/";
        }, 500);
      } else {
        setMessage("Unexpected state");
      }

      setIsProcessing(false);
    };
    return (
      <form onSubmit={handleSubmit}>
        <CardElement options={options} />
        <button disabled={isProcessing} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    );
  };

  return (
    <>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </>
  );
};

export default Stripe;
