import "./mycarousel.css";
import React, { useEffect } from "react";

function Mycarousel(props) {
  useEffect(() => {
    const slider = document.querySelector(".carousel-wrap");
    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function(e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function(event) {
      mouseDown = false;
    };

    slider.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);

    let timer;
    const next = document.getElementById("slides-arrow-next");
    const prev = document.getElementById("slides-arrow-prev");

    next.addEventListener("mousedown", () => {
      move(true, "next");
    });

    next.addEventListener("mouseup", () => {
      move(false);
    });

    next.addEventListener("mouseleave", () => {
      move(false);
    });

    prev.addEventListener("mousedown", () => {
      move(true, "back");
    });

    prev.addEventListener("mouseup", () => {
      move(false);
    });

    prev.addEventListener("mouseleave", () => {
      move(false);
    });

    function move(move, direction) {
      if (!move) {
        clearInterval(timer);
      }
      if (move)
        timer = setInterval(() => {
          if (direction == "next") slider.scrollLeft = slider.scrollLeft + 200;
          if (direction == "back") slider.scrollLeft = slider.scrollLeft - 200;
        }, 100);
    }
  }, []);

  return (
    <>
      <div className="carousel-parent">
        <button class="slide-arrows" id="slides-arrow-prev">
          &#8249;
        </button>
        <button class="slide-arrows" id="slides-arrow-next">
          &#8250;
        </button>

        <div className="carousel-wrap">
          <div className="slide-test"></div>
          <div className="slide-test blue"></div>
          <div className="slide-test"></div>
          <div className="slide-test blue"></div>
          <div className="slide-test"></div>
          <div className="slide-test blue"></div>
          <div className="slide-test"></div>
          <div className="slide-test blue"></div>
          <div className="slide-test"></div>
          <div className="slide-test blue"></div>
          <div className="slide-test"></div>
          <div className="slide-test blue"></div>
          <div className="slide-test"></div>
          <div className="slide-test blue"></div>
        </div>
      </div>
    </>
  );
}

export default Mycarousel;
