import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import { json } from "react-router-dom";
import "../font-awesome-4.7.0/css/font-awesome.css";
import "./rating.css";
const Ratings = (props) => {
  const [myDisplay, setMyDisplay] = useState();
  const { display, handleClose, TourID, getReviews } = props;
  let starCount = 0;
  let timerRunning = false;
  const postReview = () => {
    const reviewName = document.getElementById("review-name");
    const reviewEmail = document.getElementById("review-email");
    const reviewDescription = document.getElementById("review-description");
    let jsonObject = JSON.stringify({
      id: TourID,
      name: reviewName.value,
      email: reviewEmail.value,
      description: reviewDescription.value,
      numStars: starCount,
    });

    let emptyFields = 0;
    if (starCount == 0) {
      emptyFields++;
      const stars = [...document.getElementsByClassName("white-rating")];
      if (!timerRunning) {
        timerRunning = true;
        stars.map((x, index) => {
          if (x.getAttribute("class").includes("flash-orange")) {
            x.classList.remove("flash-orange");
          } else {
            x.classList.add("flash-orange");
          }
        });
        let runs = 0;
        const myTimer = setInterval(() => {
          stars.map((x, index) => {
            if (x.getAttribute("class").includes("flash-orange")) {
              x.classList.remove("flash-orange");
            } else {
              x.classList.add("flash-orange");
            }
          });
          if (runs > 5) {
            clearInterval(myTimer);
            stars.map((x, index) => {
              x.classList.remove("flash-orange");
            });
            timerRunning = false;
          }
          runs++;
        }, 700);
      }
    }
    if (reviewName.value == "" || reviewName.value == null) {
      emptyFields++;
      reviewName.style.backgroundColor = "#ff000042";
    }

    if (reviewEmail.value == "" || reviewEmail.value == null) {
      emptyFields++;
      reviewEmail.style.backgroundColor = "#ff000042";
    }

    if (reviewDescription.value == "" || reviewDescription.value == null) {
      emptyFields++;
      reviewDescription.style.backgroundColor = "#ff000042";
    }

    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
      method: "POST",
      headers: myHeaders,
      body: jsonObject,
    };

    return new Promise((resolve, reject) => {
      //let url = "https://cleversnail.backendless.app/api/data/reviews";
      let url = "https://api.smellsandsounds.co.uk/reviews"
      if (emptyFields > 0) {
        reject({ error: emptyFields });
      } else {
        fetch(url, myInit)
          .then((response) => response.json())
          .then((data) => {
            //console.log(data[0].category.split(","));
            resolve(data);
          });
      } //end else if no empty Fields
    });
  }; //end postReview
  useEffect(() => {
    setMyDisplay(display);
  }, [display]);
  return (
    <>
      <div
        className="rating-modal"
        style={{ display: myDisplay ? myDisplay : "none" }}
      >
        <div className="rating-container">
          <header>
            <Rating
              emptySymbol="fa fa-star-o fa-2x white-rating"
              fullSymbol="fa fa-star fa-2x gold-rating"
              onChange={(value) => {
                starCount = value;
              }}
            />

            <span
              onClick={() => {
                handleClose();
              }}
              className="close"
            >
              X
            </span>
          </header>
          <div className="rating-body">
            <div className="rating-section">
              <label htmlFor="name">Name</label>
              <input
                onClick={(e) => {
                  e.target.style.backgroundColor = "white";
                }}
                id="review-name"
                name="name"
                type="text"
              ></input>
              <label htmlFor="email">Email</label>
              <input
                onClick={(e) => {
                  e.target.style.backgroundColor = "white";
                }}
                id="review-email"
                name="email"
                type="email"
              ></input>
            </div>
            <div className="rating-section description">
              <label htmlFor="description">Write a review</label>
              <textarea
                onClick={(e) => {
                  e.target.style.backgroundColor = "white";
                }}
                id="review-description"
                name="description"
              ></textarea>
            </div>
          </div>
          <footer>
            <button
              onClick={async () => {
                const data = await postReview().catch((data) => {
                  console.log(data.error);
                });
                if (data) {
                  getReviews(TourID);
                  handleClose();
                }
              }}
            >
              Submit
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Ratings;
